export default {
    en: {
        tagPay: "Paid",
        tagNotPay: "Not Paid",
        titleOffline: "Offline",
        titleOnline: "Online",
        titleMan: "Manual",
        titleSub: "Subscription"
    },
    zh: {
        tagPay: "已付款",
        tagNotPay: "未付款",
        titleOffline: "线下支付",
        titleOnline: "线上支付",
        titleMan: "管理员添加",
        titleSub: "订阅"
    }
}