<template>
    <van-dialog v-model:show="show" title="管理员笔记" show-cancel-button confirm-button-text="更新" @confirm="onConfirm">
        <van-divider />
        <div class="overflow-y-scroll my-max-h pb-8">
            <van-field v-model="note.text" label="笔记内容" placeholder="请输入" />
            <van-cell title="更新时间" :value="note.time" />
            <van-cell title="笔记作者" :value="note.name" />
            <div class="p-4 flex flex-row justify-center">
                <van-uploader v-model="fileList" :after-read="loadIcon" :max-count="1" preview-size="128px"
                    :max-size="1500 * 1024" @oversize="onOversize" :before-read="checkIcon" />
            </div>
        </div>
    </van-dialog>
</template>

<script>
import Env from "@/logics/Envs.js";
import {
    ref
} from "vue";
import {
    Field,
    Dialog,
    Divider,
    Cell,
    Uploader
} from "vant";
import axios from "axios";
import SetTransProp from "@/asyncs/SetTransactionProp.js";
import ViewImage from "@/asyncs/ViewImage.js";

export default {
    components: {
        [Field.name]: Field,
        [Dialog.Component.name]: Dialog.Component,
        [Divider.name]: Divider,
        [Cell.name]: Cell,
        [Uploader.name]: Uploader
    },
    setup(props, {
        emit
    }) {
        const show = ref(false);
        const note = ref({
            text: ""
        });
        let transId;
        const showMe = async function (n, tid) {
            transId = tid;
            fileList.value = [];
            if (n) {
                note.value = JSON.parse(JSON.stringify(n));
                if (note.value.img) {
                    const imageUrl = await ViewImage("notes/" + note.value.img);
                    fileList.value.push({
                        url: imageUrl,
                        isImage: true
                    });
                }
                if (note.value.time) {
                    note.value.time = new Date(note.value.time).toLocaleString();
                }
            } else {
                note.value = {
                    text: ""
                };
            }
            show.value = true;
        };
        const fileList = ref([]);
        const loadIcon = async function (file) {
            if (fileList.value.length > 1) {
                fileList.value.shift();
            }
            if (fileList.value.length === 1 && transId) {
                file.status = "uploading";
                file.message = "Uploading...";

                const toSend = new FormData();
                toSend.append("file", fileList.value[0].file);
                toSend.append("transId", transId);
                toSend.append("group", "notes");

                const res = await axios.post(Env.apiPath + "uploadImage", toSend);
                if (res.data.error) {
                    alert(res.data.error);
                } else {
                    note.value.img = res.data.fileName;
                    note.value.time = res.data.time;
                }
                file.status = "";
                file.message = "";
                emit("notesUpdated", note.value);
            }
        };
        const checkIcon = function (file) {
            if (file.type !== "image/jpeg" &&
                file.type !== "image/png") {
                alert("图片格式不支持");
                return false;
            }
            return true;
        };
        const onOversize = function () {
            alert("图片有点大");
        };
        const onConfirm = async function () {
            note.value.time = Date.now();
            const error = await SetTransProp(transId, "notes", note.value);
            if (!error) {
                emit("notesUpdated", note.value);
            }
        };
        return {
            show,
            note,
            showMe,
            fileList,
            loadIcon,
            checkIcon,
            onConfirm,
            onOversize
        };
    }
}
</script>

<style scoped>
.my-max-h {
    max-height: 60vh;
    scrollbar-width: none;
}

.my-max-h::-webkit-scrollbar {
    width: 0px;
}

.my-max-h::-webkit-scrollbar-track {
    display: none;
}
</style>
