<template>
<div class="sm:hidden w-full">
    <van-cell v-for="(trans, index) in transactions" :key="index" :title="trans.plan" @click="clickItem(trans)" is-link>
        <template #label>
            <div class="flex flex-col">
                <span>{{getDateText(trans.ts)}}</span>
            </div>
        </template>
        <template #value>
            <div class="flex flex-col items-end pt-1">
                <van-tag v-if="trans.confirmed" type="success">{{lang[langCode].tagPay}}</van-tag>
                <van-tag v-else type="danger">{{lang[langCode].tagNotPay}}</van-tag>
                <span class="mt-1">${{trans.cost}}</span>
            </div>
        </template>
    </van-cell>
</div>
<div class="hidden sm:block w-full" v-for="(trans, index) in transactions" :key="index">
    <van-grid :border="false" column-num="6" class="cursor-pointer" @click="clickItem(trans)">
        <van-grid-item>
            <template #text>
                <span class="text-sm text-black">{{trans.plan}}</span>
            </template>
        </van-grid-item>
        <van-grid-item>
            <template #text>
                <span class="text-xs text-gray-500">{{getDateText(trans.ts)}}</span>
            </template>
        </van-grid-item>
        <van-grid-item>
            <template #text>
                <span v-if="trans.type === 'off'" class="text-xs text-gray-500">{{lang[langCode].titleOffline}}</span>
                <span v-else-if="trans.type === 'on'" class="text-xs text-gray-500">{{lang[langCode].titleOnline}}</span>
                <span v-else-if="trans.type === 'man'" class="text-xs text-gray-500">{{lang[langCode].titleMan}}</span>
            </template>
        </van-grid-item>
        <van-grid-item>
            <template #text>
                <span class="text-xs text-gray-500">${{trans.cost}}</span>
            </template>
        </van-grid-item>
        <van-grid-item>
            <template #text>
                <span v-if="trans.notes" class="text-xs text-gray-500 overflow-x-hidden w-16 whitespace-nowrap">
                    {{trans.notes.text}}
                </span>
            </template>
        </van-grid-item>
        <van-grid-item>
            <template #text>
                <van-tag v-if="trans.confirmed" type="success">{{lang[langCode].tagPay}}</van-tag>
                <van-tag v-else type="danger">{{lang[langCode].tagNotPay}}</van-tag>
            </template>
        </van-grid-item>
    </van-grid>
    <van-divider v-if="index < transactions.length - 1" />
</div>
</template>

<script>
import lang from "@/langs/TransactionListLang.js";
import {
    CellGroup,
    Cell,
    Tag,
    Grid,
    GridItem,
    Divider
} from "vant";

export default {
    components: {
        [CellGroup.name]: CellGroup,
        [Cell.name]: Cell,
        [Tag.name]: Tag,
        [Grid.name]: Grid,
        [GridItem.name]: GridItem,
        [Divider.name]: Divider
    },
    props: {
        transactions: Array,
        langCode: {
            type: String,
            default: "zh"
        }
    },
    setup(props, {
        emit
    }) {
        const clickItem = function (item) {
            emit("clickItem", item);
        };
        const getDateText = function (timestamp) {
            const date = new Date(timestamp);
            const text = date.toLocaleString("default", {
                month: "short"
            }) + " " + date.getDate() + ", " + date.getFullYear();
            return text;
        };
        return {
            lang,
            clickItem,
            getDateText
        };
    }
}
</script>
