import axios from "axios";
import CheckHttp from "@/asyncs/MyHttpCheck.js";
import {
    Notify
} from "vant";
import Env from "@/logics/Envs.js";

export default async function (tid, propName, data) {
    try {
        const toSend = new FormData();
        toSend.append("transId", tid);
        toSend.append("propName", propName);
        toSend.append("rawData", JSON.stringify(data));
        const res = await axios.post(Env.apiPath + "setTransProp", toSend);
        if (res.data.error) {
            Notify({
                type: "danger",
                message: res.data.error,
                duration: 5000
            });
            return res.data.error;
        }
        return null;
    } catch (err) {
        CheckHttp(err);
        return err;
    }
}