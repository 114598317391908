import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (key) {
    try {
        const params = { key };
        const res = await axios.get(Env.apiPath + "viewImage", { params });
        if (res.data.error) {
            console.log(res.data.error);
            return null;
        }
        return res.data.url;
    } catch (err) {
        return null;
    }
}