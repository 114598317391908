export default {
    en: {
        title: "Payment Details",
        className: "Class Name",
        planName: "Plan Name",
        planType: "Payment Method",
        planCost: "Plan Cost",
        time: "Payment Time",
        code: "Discount Code",
        offline: "Offline",
        online: "Online",
        manager: "Manual",
        sub: "Subscription",
        none: "None",
        memo: "Memo"
    },
    zh: {
        title: "支付详情",
        className: "课程名称",
        planName: "支付计划",
        planType: "支付方法",
        planCost: "支付金额",
        time: "支付时间",
        code: "是否有邀请码",
        offline: "线下支付",
        online: "线上支付",
        manager: "管理员添加",
        sub: "订阅",
        none: "无",
        memo: "备忘"
    }
}