import axios from "axios";
import Env from "@/logics/Envs.js";
export default async function (transactionId, confirm) {
    try {
        const params = { transactionId, confirm };
        const res = await axios.get(Env.apiPath + "confirmPay", { params });
        return res.data.error;
    } catch (err) {
        return err;
    }
}