<template>
<van-dialog v-model:show="show" :title="lang[langCode].title" :closeOnClickOverlay="true" :showConfirmButton="false">
    <van-divider />
    <div class="overflow-y-scroll my-max-h">
        <van-cell :title="lang[langCode].className" :value="className" />
        <van-cell :title="lang[langCode].planName" :value="plan" />
        <van-cell :title="lang[langCode].planType" :value="type" />
        <van-cell :title="lang[langCode].planCost" :value="input" v-if="input" />
        <van-cell :title="lang[langCode].planCost" :value="cost" v-else />
        <van-cell :title="lang[langCode].time" :value="ts" />
        <van-cell :title="lang[langCode].code" :value="code" />
        <van-cell :title="lang[langCode].memo" :value="memo" v-if="memo" />
        <van-cell v-if="isManager" title="Stripe" value="点击查看" is-link @click="viewStripe()" />
        <div class="flex flex-col" v-if="isManager">
            <van-button v-if="confirmed" class="block m-4" round type="danger" @click="sendConfirm(false)">取消确认</van-button>
            <van-button v-else class="block m-4" round type="primary" @click="sendConfirm(true)">确认支付</van-button>
            <van-button v-if="!confirmed" class="block m-4" round type="danger" @click="deleteRecord()">删除记录</van-button>
        </div>
    </div>
</van-dialog>
</template>

<script>
import lang from "@/langs/TransactionDetailsLang.js";
import {
    ref
} from "vue";
import {
    Cell,
    Dialog,
    Divider,
    Button,
    Toast
} from "vant";
import ConfirmPay from "@/asyncs/ConfirmPay.js";

export default {
    components: {
        [Cell.name]: Cell,
        [Dialog.Component.name]: Dialog.Component,
        [Divider.name]: Divider,
        [Button.name]: Button
    },
    props: {
        isManager: Boolean,
        langCode: {
            type: String,
            default: "zh"
        }
    },
    setup(props, {
        emit
    }) {
        const show = ref(false);
        const className = ref("");
        const cost = ref("");
        const plan = ref("");
        const type = ref("");
        const ts = ref("");
        const code = ref("");
        const confirmed = ref(false);
        const input = ref("");
        const memo = ref("");
        let subId = "";
        let transactionObject = null;
        let id = "";
        const showDialog = function (transaction) {
            id = transaction.id;
            className.value = transaction.className;
            plan.value = transaction.plan;
            cost.value = "$" + transaction.cost;
            if (transaction.type === "off") {
                type.value = lang[props.langCode].offline;
                if (transaction.input) {
                    input.value = transaction.input;
                }
            } else if (transaction.type === "on") {
                type.value = lang[props.langCode].online;
            } else if (transaction.type === "man") {
                type.value = lang[props.langCode].manager;
            }
            const date = new Date(transaction.ts);
            ts.value = date.toLocaleString();
            if (transaction.code) {
                code.value = transaction.code;
            } else {
                code.value = lang[props.langCode].none;
            }
            subId = transaction.item;
            confirmed.value = transaction.confirmed;
            memo.value = transaction.memo;
            transactionObject = transaction;
            show.value = true;
        };
        const viewStripe = function () {
            window.open("https://dashboard.stripe.com/payments/" + subId, "_blank");
        };
        const sendConfirm = async function (confirm) {
            const error = await ConfirmPay(transactionObject.id, confirm);
            if (error) {
                Toast.fail(error);
            }
            show.value = false;
            transactionObject.confirmed = confirm;
        };
        const deleteRecord = function () {
            emit("deleteRecord", id);
            show.value = false;
        };
        return {
            lang,
            show,
            showDialog,
            className,
            cost,
            plan,
            type,
            ts,
            code,
            viewStripe,
            confirmed,
            sendConfirm,
            deleteRecord,
            input,
            memo
        };
    }
}
</script>

<style scoped>
.my-max-h {
    max-height: 60vh;
    scrollbar-width: none;
}

.my-max-h::-webkit-scrollbar {
    width: 0px;
}

.my-max-h::-webkit-scrollbar-track {
    display: none;
}
</style>
