<template>
    <van-dialog v-model:show="show" :title="lang[langCode].title" :close-on-click-overlay="true" show-cancel-button
        :confirm-button-color="'#ee0a24'" :confirm-button-text="'添加'" @confirm="confirm()">
        <van-divider />
        <div class="overflow-y-scroll my-max-h">
            <van-cell :title="lang[langCode].className" :value="tran.className" />
            <van-field v-model="tran.plan" placeholder="点击手动写入">
                <template #label>
                    <div class="py-2">{{ lang[langCode].planName }}</div>
                </template>
                <template #button>
                    <van-button size="small" type="primary" class="my-1" @click="showPlanNames = true">快速选项</van-button>
                </template>
            </van-field>
            <van-field :label="lang[langCode].planCost" v-model.number="tran.cost" type="number" />
            <van-field v-model="tran.meta.feeShareTypes" is-link readonly label="费用类型" placeholder="选择费用类型"
                @click="showShareOptions = true" />
            <van-field :label="lang[langCode].memo" v-model="tran.memo" placeholder="随便写" />
        </div>
    </van-dialog>
    <van-action-sheet v-model:show="showPlanNames" :actions="planNames" cancel-text="取消" @select="onSelectPlan" />
    <van-popup v-model:show="showShareOptions" position="bottom" round>
        <van-picker show-toolbar :columns="shareOptions" @cancel="showShareOptions = false" @confirm="onSelectOption" />
    </van-popup>
</template>

<script>
import lang from "@/langs/TransactionDetailsLang.js";
import {
    ref
} from "vue";
import {
    Cell,
    Dialog,
    Divider,
    Button,
    Field,
    ActionSheet,
    Picker,
    Popup
} from "vant";

export default {
    components: {
        [Cell.name]: Cell,
        [Dialog.Component.name]: Dialog.Component,
        [Divider.name]: Divider,
        [Button.name]: Button,
        [Field.name]: Field,
        [ActionSheet.name]: ActionSheet,
        [Picker.name]: Picker,
        [Popup.name]: Popup
    },
    props: {
        langCode: {
            type: String,
            default: "zh"
        }
    },
    setup(props, {
        emit
    }) {
        const show = ref(false);
        const tran = ref({});
        const planNames = ref([]);
        const shareOptions = ref([]);
        const showPlanNames = ref(false);
        const showShareOptions = ref(false);
        const showDialog = async function (transaction, planNameActions, shareActions) {
            transaction.cost = 0;
            if (!transaction.meta) {
                transaction.meta = {};
            }
            tran.value = transaction;
            planNames.value = planNameActions;
            shareOptions.value = shareActions;
            show.value = true;
        };
        const confirm = function () {
            emit("confirmAdd", tran.value);
        };
        const onSelectPlan = function (e) {
            tran.value.plan = e.name;
            showPlanNames.value = false;
        };
        const onSelectOption = function (e) {
            tran.value.meta.feeShareTypes = e;//"feeShareTypes" need static
            showShareOptions.value = false;
        };
        return {
            lang,
            show,
            showDialog,
            tran,
            confirm,
            planNames,
            shareOptions,
            showPlanNames,
            showShareOptions,
            onSelectPlan,
            onSelectOption
        };
    }
}
</script>

<style scoped>
.my-max-h {
    max-height: 60vh;
    scrollbar-width: none;
}

.my-max-h::-webkit-scrollbar {
    width: 0px;
}

.my-max-h::-webkit-scrollbar-track {
    display: none;
}
</style>
