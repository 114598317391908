<template>
    <div class="pt-32 flex flex-wrap" v-if="user">
        <div class="md:w-1/3 w-full mb-12">
            <van-cell-group class="shadow-md" inset v-if="student">
                <profile-cell :user="student" :lang-code="'zh'" />
            </van-cell-group>
            <div class="my-8 px-4">
                <van-button v-if="!nowAll && isManager" class="w-full my-2" type="primary"
                    @click="goAll()">查看所有记录</van-button>
                <van-button v-if="!nowAll && (isManager || isTeacher)" class="w-full my-2" type="primary"
                    @click="addTrans()">手动添加记录</van-button>
            </div>
        </div>
        <div class="md:w-2/3 w-full">
            <van-cell-group class="shadow-md py-4" v-if="transactions" inset>
                <transaction-list :transactions="transactions" @click-item="clickTrans" />
            </van-cell-group>
        </div>
        <transaction-dialog ref="refTranDetails" :is-Manager="isManager" @delete-record="deleteRecord" />
        <add-transaction ref="refAddTran" @confirm-add="addRecord" />
        <van-action-sheet v-model:show="showActions" :actions="actions" @select="onSelect" />
        <notes-dialog ref="refNotesDialog" @notes-updated="notesUpdated" />
    </div>
</template>

<script>
import {
    ref,
    computed,
    onMounted
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRoute,
    useRouter
} from "vue-router";
import {
    Notify,
    CellGroup,
    Button,
    ActionSheet
} from "vant";
import UserTypes from "@/static_values/StaticUserTypes.js";
import ProfileCell from "@/components/ProfileCell.vue";
import TransactionList from "@/components/list/TransactionList.vue";
import TransactionDialog from "@/components/dialog/TransactionDetails.vue";
import NotesDialog from "@/components/dialog/ManagerNotes.vue";
import GetSession from "@/asyncs/GetSession.js";
import ViewUser from "@/asyncs/ViewUser.js";
import ListTransactions from "@/asyncs/ListTransactions.js";
import DeleteTransaction from "@/asyncs/DeleteTransaction.js";
import AddTransaction from "@/components/dialog/TransactionAdd.vue";
import AddTransactionRecord from "@/asyncs/AddTransaction.js";
import ListProp from "@/asyncs/ListClassProp.js";
import LoadOptions from "@/asyncs/LoadOptions.js";

export default {
    components: {
        [CellGroup.name]: CellGroup,
        [Button.name]: Button,
        [ActionSheet.name]: ActionSheet,
        ProfileCell,
        TransactionList,
        TransactionDialog,
        AddTransaction,
        NotesDialog
    },
    setup() {
        const store = useStore();
        store.commit("showBack");
        store.commit("showMenu");
        const route = useRoute();
        const router = useRouter();
        const modeAll = "all";
        const modePay = "pay";
        const student = ref(null);
        const transactions = ref(null);
        let planNames = [];
        let optionNames = [];
        let className = "";
        onMounted(() => {
            GetSession({
                store,
                router,
                route
            }, async function () {
                student.value = await ViewUser(route.params.studentId);
                if (!student.value) {
                    return;
                }
                if (route.params.mode === modePay) {
                    store.commit("setPageTitle", "缴费记录");
                    if (route.params.targetId === modeAll) {
                        transactions.value = await ListTransactions(route.params.studentId);
                    } else {
                        transactions.value = await ListTransactions(route.params.studentId, route.params.targetId);
                        planNames = await LoadOptions("feeNames");
                        optionNames = await LoadOptions("feeShareTypes");
                        const dict = await ListProp([route.params.targetId], "className");
                        className = dict[route.params.targetId];
                    }
                }
                if (UserTypes.manager.value !== store.state.user.userType) {
                    actions.pop();
                }
            });
        });
        const refTranDetails = ref(null);
        let viewingTransaction = null;
        const clickTrans = function (item) {
            showActions.value = true;
            viewingTransaction = item;
        };
        const refNotesDialog = ref(null);
        const showActions = ref(false);
        const actions = [{
            id: 1,
            name: "支付详情"
        },
        {
            id: 2,
            name: "管理员笔记"
        }
        ];
        const onSelect = function (item) {
            showActions.value = false;
            if (viewingTransaction) {
                if (item.id === 1) {
                    refTranDetails.value.showDialog(viewingTransaction);
                } else if (item.id === 2) {
                    refNotesDialog.value.showMe(viewingTransaction.notes, viewingTransaction.id);
                }
            }
        };
        const deleteRecord = async function (id) {
            let index = 0;
            let list = transactions.value;
            while (index < list.length) {
                if (id === list[index].id) {
                    const error = await DeleteTransaction(id);
                    if (error) {
                        Notify({
                            type: "danger",
                            message: error
                        });
                    } else {
                        Notify({
                            type: "success",
                            message: "已删除"
                        });
                        list.splice(index, 1);
                    }
                    break;
                }
                index += 1;
            }
        };
        const goAll = function () {
            router.push("/studentDetails/" + route.params.studentId + "/" + modePay + "/" + modeAll);
        };
        const refAddTran = ref(null);
        const addTrans = async function () {
            refAddTran.value.showDialog({
                userId: route.params.studentId,
                classId: route.params.targetId,
                className
            }, planNames.map(function (item) {
                return { name: item.text }
            }), optionNames.map(item => item.text));
        };
        const addRecord = async function (tran) {
            const transaction = await AddTransactionRecord(tran)
            if (transaction) {
                transactions.value.push(transaction);
            }
        };
        const notesUpdated = function (notes) {
            viewingTransaction.notes = notes;
        };
        return {
            student,
            transactions,
            clickTrans,
            refTranDetails,
            user: computed(() => store.state.user),
            isManager: computed(() => UserTypes.manager.value === store.state.user.userType),
            isTeacher: computed(() => UserTypes.teacher.value === store.state.user.userType),
            deleteRecord,
            goAll,
            nowAll: computed(() => route.params.targetId === modeAll),
            refAddTran,
            addTrans,
            addRecord,
            showActions,
            actions,
            onSelect,
            refNotesDialog,
            notesUpdated
        };
    }
}
</script>
