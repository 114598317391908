import axios from "axios";
import CheckHttp from "@/asyncs/MyHttpCheck.js";
import {
    Notify
} from "vant";
import Env from "@/logics/Envs.js";

export default async function (transaction) {
    try {
        const toSend = new FormData();
        toSend.append("data", JSON.stringify(transaction));
        const res = await axios.post(Env.apiPath + "addTransaction", toSend);
        if (res.data.error) {
            Notify({
                type: "danger",
                message: res.data.error,
                duration: 5000
            });
            return null;
        }
        return res.data.transaction;
    } catch (err) {
        CheckHttp(err);
        return null;
    }
}